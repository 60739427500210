import React, { useCallback, useEffect, useRef } from 'react'
import { useState, useMemo } from "react";

export const Test = () => {
    const [count, setCount] = useState(0);
    const [todos, setTodos] = useState([]);
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        console.log('testasd');
    })
    const expensiveCalculation = (num) => {
        // console.log("Calculating...");
        // for (let i = 0; i < 1000000000; i++) {
        //   num += 1;
        // }
        return num;
    };
    const [num, setNum] = useState(1);
    const [total, setTotal] = useState(0);
    const calculation = useMemo(() => expensiveCalculation(count), [count]);
    // console.log(tableData);
    const [name, setName] = useState('');
    const inputRef = useRef(null);

    function handleClick() {
        inputRef.current.focus();
        console.log(inputRef.current.value);
    }

    const increment = () => {
        setCount((c) => c + 1);
    };
    const addTodo = () => {
        setTodos((t) => [...t, "New Todo"]);
    };
    const updateTableData = () => {
        setNum(num + 1);
        let obj = {
            balls : num,
            score : null
        }
        if (num < 7) {
            setTableData((t) => [...t, obj]);
        }
    }
    const scoreChange = (e, index) => {
        let objIndex = tableData.findIndex((obj, i) => {
            return i === index
        });
        if (objIndex !== -1) {
            tableData[objIndex].score = e.target.value;
        }
        setTableData([...tableData]);
        let total = 0;
        tableData.map((data) => {
            total += parseInt(data.score);
        })
        if (tableData.length === 6) {
            setTotal(total);
        }
    }
    const items = [{
        id: 1,
        title: 'First Item',
        content: 'First Item Content',
    }, {
        id: 2,
        title: 'Second Item',
        content: 'Second Item Content',
    }]
    const [visibleItems, setVisibleItems] = useState([]);
    const toggleItemVisibility = useCallback((itemId) => {
        console.log('testestsw');        
        if (visibleItems.includes(itemId)) {
            setVisibleItems(visibleItems.filter((id) => id !== itemId));
        } else {
            setVisibleItems([...visibleItems, itemId]);
        }
    }, [visibleItems]);
    return (
        <div>
            {/* <div>
                <h2>My Todos</h2>
                {todos.map((todo, index) => {
                return <p key={index}>{todo}</p>;
                })}
                <button onClick={addTodo}>Add Todo</button>
            </div>
            <hr />
            <div>
                Count: {count}
                <button onClick={increment}>+</button>
                <h2>Expensive Calculation</h2>
                {calculation}
            </div> */}
            <div>
                <button className='btn btn-primary' onClick={updateTableData}>Update</button>
                <table>
                    <thead>
                        <tr>
                            <th>Balls</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>{data.balls}</td>
                                    <td><input type="text" onChange={(e) => scoreChange(e, index)} value={data.score} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div>Total : {total}</div>
            </div>
            {items.map((item) => (
                <div key={item.id}>
                <h2>{item.title}</h2>
                <button onClick={() => toggleItemVisibility(item.id)}>
                    {visibleItems.includes(item.id) ? 'Hide' : 'Show'}
                </button>
                {visibleItems.includes(item.id) && <p>{item.content}</p>}
                </div>
            ))}
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                ref={inputRef}
            />
            <button onClick={handleClick}>Focus</button>
        </div>
    )
}
