import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css'
import './assets/css/tagify.css'
import { Registeration } from './Components/Registeration';
import { Login } from './Components/Login';
import { Profile } from './Components/Profile';
import { SingleArticle } from './Components/SingleArticle';
import { SingleArticleListing } from './Components/SingleArticleListing';
import { BatchArticleListing } from './Components/BatchArticleListing';
import { SubArticles } from './Components/SubArticles';
import { MultipleGenerateArticle } from './Components/MultipleGenerateArticle';
import { Dashboard } from './Components/Dashboard';
import { ChangePassword } from './Components/ChangePassword';
import { Integration } from './Components/Integration';
import { Wordpress } from './Components/Wordpress';
import TransitionWrapper from './Components/TransitionWrapper';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PageNotFound } from './Components/404';
import { Test } from './Components/Test';

function App() {
  const location = useLocation();
  const allRoutes = [
    {
      path: "registeration",
      element: <Registeration />
    },
    {
      path: "/",
      element: <Login />
    },
    {
      path: "profile",
      element: <Profile />
    },
    {
      path: "single_article",
      element: <SingleArticle />
    },
    {
      path: "single_article_list",
      element: <SingleArticleListing />
    },
    {
      path: "bulk_article_list",
      element: <BatchArticleListing />
    },
    {
      path: "sub_articles/:slug",
      element: <SubArticles />
    },
    {
      path: "bulk_article",
      element: <MultipleGenerateArticle />
    },
    {
      path: "dashboard",
      element: <Dashboard />
    },
    {
      path: "change_password",
      element: <ChangePassword />
    },
    {
      path: "integration",
      element: <Integration />
    },
    {
      path: "wordpress",
      element: <Wordpress />
    },
    {
      path : '*',
      element : <PageNotFound />
    },
    {
      path : 'test',
      element : <Test />
    }
  ]
  return (
    <>
      <TransitionWrapper>
        <Routes location={location}>
          {allRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </TransitionWrapper>
    </>
  );
}

export default App;
