import React, { useState } from 'react'
import articleService from '../Services/article.service'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Select } from './Select'
import { Main } from './Main'

export const MultipleGenerateArticle = () => {
    const [inputVal, setInputVal] = useState('')
    const [input, setInput] = useState({
        language : '0',
        tone : '0',
        wordCount : '0',
        projectType : '0',
        projectInputs : 'Article keywords',
        projectName : ''
    })
    const [keywords, setKeywords] = useState([])
    const [errors, setErrors] = useState({
        language : '',
        tone : '',
        wordCount : '',
        projectType : '',
        projectInputs : '',
        projectName : ''
    })
    const [flag, setFlag] = useState(false)
    const user = useSelector((state) => state?.auth?.user?.data)

    let toneOpt = [
        { value : 'Formal', label : 'Formal' },
        { value : 'Informal', label : 'Informal' },
        { value : 'Funny', label : 'Funny' },
    ]

    let languageOpt = [
        { value : 'English', label : 'English' },
        { value : 'French', label : 'French' },
        { value : 'Spanish', label : 'Spanish' },
    ]

    let wordCountOpt = [
        { value : '500', label : '500' },
        { value : '1000', label : '1000' },
        { value : '1500+', label : '1500+' },
    ]

    let projectTypeOpt = [
        { value : 'How to', label : 'How to' },
        { value : 'Reviews', label : 'Reviews' },
        { value : 'Top best', label : 'Top best' },
        { value : 'Alternatives', label : 'Alternatives' },
        { value : 'Versus', label : 'Versus' },
        { value : 'Others', label : 'Others' },
    ]

    const sendData = (e) => {
        e.preventDefault()
        const allErrors = {}
        for (const key in input) {
            if (!input[key].trim() || input[key] === '0') {
                allErrors[key] = `Please enter a value of ${key}.`;
            } else {
                allErrors[key] = '';
            }
        }
        if (keywords.length === 0) {
            allErrors.keywords = 'Please enter atleast one keyword.'
        }
        setErrors(allErrors)
        if (Object.values(allErrors).every((val) => !val) && keywords.length > 0) {
            setFlag(true)
            let data = {
                keywords : keywords,
                project : input.projectName,
                type : 0,
                user_id : user?._id,
                language : input.language,
                tone : input.tone,
                wordCount : input.wordCount,
                projectType : input.projectType
            }
            articleService.createMultipleArticles(data).then((res) => {
                toast.success(res?.message)
                setFlag(false)
                setKeywords([])
                setInput({
                    language : '0',
                    tone : '0',
                    wordCount : '0',
                    projectType : '0',
                    projectInputs : '0',
                    projectName : ''
                })
            }).catch((err) => {
                console.log(err);
            })
        }
    }
    const handleKeyDown = (event) => {
        // if (event.key === "Enter") {
        //     event.preventDefault()
        //     setKeywords([...keywords, event.target.value])
        //     setInputVal('')
        // }
        if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault()
            errors.keywords = ''
            let newVal = event.target.value?.split('\n')
            setKeywords([...keywords, ...newVal])
            setInputVal('')
        }
    }

    const removeItem = (key) => {
        let filteredList = keywords.filter((_, index) => {
            return key !== index
        })
        setKeywords(filteredList)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInput(values => ({...values, [name]: value}))
    }

    return (
        <Main route="bulk_article">
            {() => (
                <div className="row mt-4">
                    <div className="col-xxl-7 col-lg-9 mx-auto">
                        <div className="article-left-side mt-lg-4">
                            <h1 className="article-main-heading text-center">Bulk Article</h1>
                            <div className="row">
                                <div className="col-12">
                                    <div className="article-input-detail article-input-box">
                                        <div className="sec-title-box">
                                            <h2>Input Detail</h2>
                                            <a className="redamore-btn cursor-pointer">Learn More</a>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Select options={languageOpt} setFunc={handleChange} name='language' value={input.language} firstOpt="Language" />
                                                {errors.language && <p className='mx-1 pt-2 text-danger'>{errors.language}</p> }
                                            </div>
                                            <div className="col-md-6">
                                                <Select options={toneOpt} setFunc={handleChange} name='tone' value={input.tone} firstOpt="Tone of voice" />
                                                {errors.tone && <p className='mx-1 pt-2 text-danger'>{errors.tone}</p> }
                                            </div>
                                            <div className="col-12 mt-3">
                                                <Select options={wordCountOpt} setFunc={handleChange} name='wordCount' value={input.wordCount} firstOpt="Word Count" />
                                                {errors.wordCount && <p className='mx-1 pt-2 text-danger'>{errors.wordCount}</p> }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="generate-mode article-input-box">
                                        <div className="sec-title-box">
                                            <h2>Project Type</h2>
                                            <a className="redamore-btn cursor-pointer">Learn More</a>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Select options={projectTypeOpt} setFunc={handleChange} name='projectType' value={input.projectType} firstOpt="Select Project Type" />
                                                {errors.projectType && <p className='mx-1 pt-2 text-danger'>{errors.projectType}</p> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="generate-mode article-input-box">
                                        <div className="sec-title-box">
                                            <h2>Project Name</h2>
                                            <a className="redamore-btn cursor-pointer">Learn More</a>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name='projectName' value={input.projectName} onChange={(e) => handleChange(e)} placeholder="Project name" />
                                                    {errors.projectName && <p className='mx-1 pt-2 text-danger'>{errors.projectName}</p> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="article-title article-input-box">
                                        <div className="sec-title-box">
                                            <h2>Project Inputs </h2>
                                            <a className="redamore-btn cursor-pointer">Learn More</a>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {/* <Select options={projectInputsOpt} setFunc={handleChange} name='projectInputs' value={input.projectInputs} firstOpt="Keywords" />
                                                {errors.projectInputs && <p className='mx-1 pt-2 text-danger'>{errors.projectInputs}</p> } */}
                                                <select className="form-select article-select" name='projectInputs' value={input.projectInputs} onChange={(e) => handleChange(e)}>
                                                    <option value='Article keywords'>Article keywords</option>
                                                    <option value='Article Title'>Article Title</option>
                                                </select>
                                                {errors.projectInputs && <p className='mx-1 pt-2 text-danger'>{errors.projectInputs}</p> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="generate-mode article-input-box">
                                        <div className="sec-title-box">
                                            <h2>Keyword List</h2>
                                            <a className="redamore-btn cursor-pointer">Learn More</a>
                                        </div>
                                        <div className="form-group tags">
                                            {/* <input type="text" className='form-control' value={inputVal} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => setInputVal(e.target.value)} placeholder='Keywords' /> */}
                                            {/* <br /> */}
                                            <div className='note'>
                                                <p className='mb-0'><b>Note: </b></p>
                                                <p className='mb-0'>One row per keyword. Press Enter to type next keyword.</p>
                                                <p>Press Shift+Enter after typing complete text.</p>
                                            </div>
                                            <textarea className='form-control' placeholder='Enter keywords' value={inputVal} name="mul_words" onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => setInputVal(e.target.value)} id="mul_words"></textarea>
                                            <div className='mt-3'>
                                                <ul className='batch_keywords_list'>
                                                    {keywords?.map((data, key) => {
                                                        return <li className='keyword_list my-2' key={key}>
                                                            <span>{data}</span>
                                                            <span className='tagify__tag__removeBtn' onClick={() => removeItem(key)}></span>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            {errors.keywords && <p className='mx-1 pt-2 text-danger'>{errors.keywords}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {flag ?
                                (
                                    <div className='text-end'>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    </div>
                                )
                            :
                            <a className="generate-btn cursor-pointer" onClick={(e) => sendData(e)}>Generate</a>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Main>
    )
}
